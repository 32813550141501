import WrapperPolicy from 'components/WrapperPolicy/WrapperPolicy';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux_utils/store';
import { fetchWpPageBySlug } from 'utils/wordPressHelpers';
import SanitizedHtmlBody from 'components/SanitizedHtmlBody';
import LoadingScreen from 'components/CircularLoader';
import { useMediaQuery } from '@mui/material';

const CookiePolicy: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const { isUserFromEurope, loading } = useAppSelector((state) => state.firebaseSlice);
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const slug = isUserFromEurope
        ? 'cookie-policy-eu'
        : 'cookie-policy-usa';
      const res = await fetchWpPageBySlug(slug);
      setData(res.content);
    };
    if (!loading) {
      fetchData();
    }
  }, [isUserFromEurope, loading]);

  return (
    <WrapperPolicy>
      <h1 style={{ textAlign: 'left', fontSize: isMobile ? '36px' : '48px' }}>Cookie Policy</h1>
      {data ? <SanitizedHtmlBody body={data} /> : <LoadingScreen />}
    </WrapperPolicy>
  );
};

export default CookiePolicy;
