export enum ROUTES {
  INTRO = '/',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_OF_SERVICE = '/terms-of-service',
  SUBSCRIPTION_TERMS = '/subscription-terms',
  COOKIE_POLICY = '/cookie-policy'
}

export enum FUNNELS_LINKS {
  LOVE_LANGUAGE = 'love-language',
  TOXIC_RELATIONSHIP = 'relationship'
}
