import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import Container, { ContainerTypeMap } from '@mui/material/Container';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

const Wrapper = (props: DefaultComponentProps<ContainerTypeMap>): ReactJSXElement => {
  const { children, ...restProps } = props;
  return (
    <Container
      sx={{
        maxWidth: '512px',
        padding: '16px 20px',
        '@media (min-width: 1200px)': { maxWidth: '512px' },
        '@media (min-width: 600px)': { padding: '16px 0' }
      }}
      {...restProps}>
      {children}
    </Container>
  );
};

export default Wrapper;
