import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { ROUTES } from 'routes';
import { Intro } from 'pages/Intro/Intro';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from 'pages/TermsOfService/TermsOfService';
import SubscriptionTerms from 'pages/SubscriptionTerms/SubscriptionTerms';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';

import 'App.scss';
import { useAppDispatch } from 'redux_utils/store';
import { fetchFirebaseConfig } from 'redux_utils/firebaseSlice';
import CookiePolicy from 'pages/CookiePolicy/CookiePolicy';

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchFirebaseConfig());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Container sx={{ padding: '0' }}>
        <Routes>
          <Route path={ROUTES.INTRO} element={<Intro />} />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.SUBSCRIPTION_TERMS} element={<SubscriptionTerms />} />
          <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfService />} />
          <Route path={ROUTES.COOKIE_POLICY} element={<CookiePolicy />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
}

export default App;
